<template>
  <div>
    <!-- スケジュール表 -->
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      <v-icon color="#1976d2" dense>mdi-chat-question</v-icon>
      <a style="text-decoration: underline" @click="jidoukoushinDialog = true"
        >自動更新について</a
      >
      &nbsp;&nbsp;&nbsp;
      <v-icon color="#1976d2" dense>mdi-chat-question</v-icon>
      <a style="text-decoration: underline" @click="ikkatsuDialog = true"
        >一括更新について</a
      >
    </div>
    <v-dialog
      v-model="jidoukoushinDialog"
      scrollable
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">自動更新ボタン</span>
        </v-card-title>
        <v-card-text>
          <ul>
            <li>
              自動更新を開始すると、キャストの勤務時間中はステータスが自動で「待機中」になります。
              「待機中」の間は、更新先の媒体において「即ヒメ」や「今すぐ遊べる」がONになるため、毎回ステータスを更新する手間が省けます。
            </li>
            <li>
              「接客中」を指定すると、「即ヒメ」や「今すぐ遊べる」が一時的にOFFになりますが、接客終了時刻を過ぎると「待機中」に戻ります。
            </li>
            <li>
              「受付終了」を指定すると、「即ヒメ」や「今すぐ遊べる」がOFFになります。
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="jidoukoushinDialog = false"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >閉じる</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ikkatsuDialog" scrollable width="100%" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">一括更新ボタン</span>
        </v-card-title>
        <v-card-text>
          <ul>
            <li>
              いますぐステータスを媒体に反映したい場合は、一括更新ボタンを使用して下さい。
            </li>
            <li>
              自動更新中は、各コンテンツ毎の更新間隔に合わせて自動で更新しますが、一括更新ボタンを使用することで、即座に反映できます。
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="ikkatsuDialog = false"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >閉じる</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-subtitle-2 pa-1 orange--text orange lighten-5">
      日付変更時刻({{ dateline }}時)に翌日の出勤キャストに表示が変わります。
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="propsCastlist"
        :item-class="itemRowBackground"
        :items-per-page="-1"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
        :mobile-breakpoint="0"
        :sort-by="[
          'working_enable',
          'working_priority',
          'work_status',
          'time_sort_key',
        ]"
        :sort-desc="['working_enable', 'working_priority']"
        item-key="cast_id"
        no-data-text="本日のスケジュールが登録されていません"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.enable="{ item }">
          <div class="mx-1" @click="onChangeListIsEnable(item)">
            <v-icon
              v-if="item.working_enable"
              class="is_auto_cursor font-bold"
              color="primary"
              large
              >mdi-toggle-switch</v-icon
            >
            <v-icon v-else large class="is_auto_cursor font-bold">
              mdi-toggle-switch-off
            </v-icon>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.working_priority="{ item }">
          <div v-if="item.working_priority">
            <v-icon @click="onChangePriority(item, false)" color="amber"
              >mdi-star</v-icon
            >
          </div>
          <div v-else>
            <v-icon @click="onChangePriority(item, true)">mdi-star</v-icon>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.schedule="{ item }">
          {{ item.start_time }}～{{ item.end_time }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.image="{ item }">
          <div class="pa-1">
            <v-img
              width="50"
              height="50"
              :src="setting.cdn + item.image"
              v-if="item.image"
            ></v-img>
            <v-img
              width="50"
              height="50"
              v-else
              src="@/assets/images/no_image.jpg"
            ></v-img>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <div
            v-if="item.work_status == setting.work_status_working"
            class="work_status_label primary--text"
          >
            出勤中
          </div>
          <div
            v-if="item.work_status == setting.work_status_before"
            class="work_status_label success--text"
          >
            出勤前
          </div>
          <div
            v-if="item.work_status == setting.work_status_after"
            class="work_status_label grey--text text--darken-2"
          >
            退勤済
          </div>
          <div class="truncate">
            {{ item.name }}
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <div class="pa-1">
            <v-icon @click="onChangeMode(item)" :color="modeColor(item)"
              >mdi-chevron-right</v-icon
            >
            &nbsp;<span
              style="cursor: pointer"
              :class="`text-align-middle` + ` ` + modeColor(item)"
              @click="onChangeMode(item)"
              >{{ setting.working_mode_label[item.mode].text
              }}<template v-if="item.mode == setting.working_mode_service"
                >（～{{ item.service_end_time }}）</template
              ></span
            >
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.comment="{ item }">
          <div class="pa-1">
            <span
              style="cursor: pointer"
              class="text-align-middle truncate_comment"
              @click="onChangeComment(item)"
              ><v-icon>mdi-pencil</v-icon>
              &nbsp;
              <span
                class="text-align-middle grey--text"
                v-if="item.working_comment === null"
                >未設定</span
              >
              <span
                class="text-align-middle"
                v-else-if="item.working_comment != ''"
                >{{ item.working_comment }}</span
              >
              <span class="text-align-middle grey--text" v-else
                >コメントなし</span
              >
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- 即姫モード切替ダイアログ -->
    <v-dialog
      v-model="modeDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">ステータス</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="refWorkingForm">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-subheader class="pl-0">
                    現在の状態を登録してください。
                  </v-subheader>
                  <v-select
                    v-model="mode"
                    :items="setting.working_mode_label"
                    no-data-text="選択してください"
                    label="ステータス"
                    success
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-subheader class="pl-0">
                    【接客中のみ】接客終了時間を設定してください。
                  </v-subheader>
                  <v-select
                    v-model="service_end_time"
                    :items="service_end_items"
                    no-data-text="選択してください"
                    label="接客終了時刻"
                    :disabled="mode != setting.working_mode_service"
                    :rules="service_end_time_rules"
                    outlined
                    dense
                  ></v-select>
                  <v-subheader class="pl-0">
                    ※ステータスをすぐに媒体へ反映したい場合は、一括更新を行ってください。
                  </v-subheader>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelModeDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitModeDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >変更</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- コメント入力ダイアログ -->
    <v-dialog
      v-model="commentDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">コメント</span>
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observerComment">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="コメント入力"
                    rules="max:50"
                  >
                    <v-text-field
                      v-model="comment"
                      name="name"
                      counter="50"
                      :error-messages="errors"
                      :success="valid"
                      hint="コメントを入力してください。対応媒体にて表示されます。"
                      label="コメント"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelCommentDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitCommentDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  set,
} from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  props: ["castlist", "dateline", "working_is_auto"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const SchedulesRepository = repositoryFactory.get("schedules");
    const WorkingCastsRepository = repositoryFactory.get("workingCasts");
    const propsCastlist = ref(props.castlist);
    const refWorkingForm = ref();
    const state = reactive({
      // 即姫モードダイアログ関連
      modeDialog: false,
      modeCastId: 0,
      mode: null,
      service_end_time: null,
      service_end_items: [],
      // コメントダイアログ関連
      commentDialog: false,
      commentCastId: 0,
      comment: "",
      // 説明ダイアログ
      jidoukoushinDialog: false,
      ikkatsuDialog: false,
      // 表関連
      search: "",
      headers: [
        {
          text: "更新する",
          value: "enable",
          sortable: false,
          width: 60,
          class: "td_enable",
        },
        {
          text: "優先",
          value: "working_priority",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "出勤時間",
          value: "schedule",
          sortable: false,
          align: "center",
          width: 120,
        },
        {
          text: "",
          value: "image",
          sortable: false,
          align: "center",
          width: 66,
        },
        {
          text: "キャスト",
          value: "name",
          sortable: false,
          class: "td_name",
        },
        {
          text: "ステータス設定",
          value: "status",
          sortable: false,
          width: 200,
        },
        {
          text: "コメント",
          value: "comment",
          sortable: false,
          class: "td_comment",
        },
      ],
    });

    const itemRowBackground = (item) => {
      const grey_color = "blue-grey lighten-4";
      const amber_color = "amber lighten-5";
      let color = "";
      if (!item.working_enable) {
        color = grey_color;
      } else if (item.work_status == setting.work_status_after) {
        color = grey_color;
      } else if (item.work_status == setting.work_status_before) {
        color = amber_color;
      }
      return color;
    };

    // castprops保存処理
    const saveProps = async (params) => {
      store.dispatch("loadingIcon/showIcon");
      let result = false;
      result = await CastPropsRepository.save_all({ cast_prop: [params] })
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return true;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/castList.vue/saveProps CastPropsRepository.save_all (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      return result;
    };

    // working_cast保存処理
    const saveWorkingCast = async (params) => {
      store.dispatch("loadingIcon/showIcon");
      const working_cast_id = await WorkingCastsRepository.save(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data.id;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/castList.vue/saveWorkingCast WorkingCastsRepository.save (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      return working_cast_id;
    };

    // 自動更新 有効・無効クリック
    const onChangeListIsEnable = async (item) => {
      // DB更新する(castProps)
      item.working_enable = !item.working_enable;
      const params = {
        cast_id: item.cast_id,
        key: "working__enable",
        value: item.working_enable ? "true" : "false",
      };
      await saveProps(params);
    };

    // 優先アイコンクリック
    const onChangePriority = async (item, flg) => {
      item.working_priority = flg;
      // DB更新する(castProps)
      const params = {
        cast_id: item.cast_id,
        key: "working__priority",
        value: item.working_priority ? "true" : "false",
      };
      await saveProps(params);
    };

    // 即姫モードの表示色
    const modeColor = (item) => {
      let str_color = "grey--text";
      if (item.mode == setting.working_mode_standby) {
        str_color = "orange--text";
      } else if (item.mode == setting.working_mode_service) {
        str_color = "pink--text";
      } else if (item.mode == setting.working_mode_end) {
        str_color = "";
      }
      return str_color;
    };
    // 即姫モードクリック
    const onChangeMode = async (item) => {
      // 退勤済の場合、何もしない
      if (item.work_status == setting.work_status_after) {
        return;
      }
      // スケジュールにあわせて接客終了時刻セレクトボックスの内容を作成する
      // 開始時刻を算出
      const scheduleDate = new Date();
      const fm = 10 - (scheduleDate.getMinutes() % 10);
      scheduleDate.setMinutes(scheduleDate.getMinutes() + fm);
      let strValue = utilDate.getTimeFormat(scheduleDate);
      state.service_end_items.push({ value: strValue, text: strValue });
      // 終了時刻まで10分刻みで時刻を算出
      while (
        utilDate.convert24TimeEnd(item.end_time, item.dateline) >
        utilDate.convert24TimeEnd(strValue, item.dateline)
      ) {
        scheduleDate.setMinutes(scheduleDate.getMinutes() + 10);
        strValue = utilDate.getTimeFormat(scheduleDate);
        state.service_end_items.push({ value: strValue, text: strValue });
      }
      // ダイアログ初期化
      state.modeCastId = item.cast_id;
      state.mode = item.mode;
      state.service_end_time = item.service_end_time;
      state.modeDialog = true;
    };
    // 即姫モード保存
    const onSubmitModeDialog = async (item) => {
      // 入力チェック
      if (state.mode == setting.working_mode_service) {
        if (!refWorkingForm.value.validate()) {
          return false;
        }
      }
      const index = propsCastlist.value.findIndex(
        (list) => list.cast_id == state.modeCastId
      );
      let params = {};
      if (index == -1) {
        alert("スケジュールが変更されているため、保存を中止します。");
        cancelModeDialog();
        return false;
      } else {
        // DB更新用パラメータを作成しておく(working_cast)
        params = {
          id: propsCastlist.value[index].id,
          shop_id: propsCastlist.value[index].shop_id,
          cast_id: propsCastlist.value[index].cast_id,
          schedule_id: propsCastlist.value[index].schedule_id,
          mode: state.mode,
          service_end_time: state.service_end_time ?? "",
        };
      }
      // スケジュールチェック(スケジュールの時刻が一致するか確認)
      const sCheck = await SchedulesRepository.get(
        propsCastlist.value[index].schedule_id
      )
        .then((response) => {
          if (response.data) {
            // データ取得OK
            return (
              propsCastlist.value[index].start_time ==
                response.data.start_time &&
              propsCastlist.value[index].end_time == response.data.end_time
            );
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
      if (!sCheck) {
        alert("スケジュールが変更されているため、保存を中止します。");
        cancelModeDialog();
        return false;
      }
      // 確認
      if (!window.confirm("ステータスを変更してよろしいですか？")) {
        return false;
      }
      // DBを更新
      await saveWorkingCast(params);
      // 再読み込みして表を更新
      ctx.emit("reload");
      // // 表を更新
      // set(propsCastlist.value[index], "id", result_id);
      // set(propsCastlist.value[index], "mode", state.mode);
      // if (state.mode == setting.working_mode_service) {
      //   set(
      //     propsCastlist.value[index],
      //     "service_end_time",
      //     state.service_end_time
      //   );
      // } else {
      //   set(propsCastlist.value[index], "service_end_time", null);
      // }
      cancelModeDialog();
    };
    // 即姫モードダイアログ閉じる
    const cancelModeDialog = () => {
      state.modeDialog = false;
      state.modeCastId = 0;
      state.mode = null;
      state.service_end_time = null;
      state.service_end_items = [];
      refWorkingForm.value.reset();
    };

    // コメントクリック コメントダイアログ開く
    const onChangeComment = async (item) => {
      state.commentDialog = true;
      state.commentCastId = item.cast_id;
      state.comment = item.working_comment;
    };
    // コメント保存
    const onSubmitCommentDialog = async (item) => {
      const isValid = await ctx.refs.observerComment.validate();
      if (!isValid) {
        return false;
      }
      // if (!window.confirm("コメントを保存します。よろしいですか？")) {
      //   return false;
      // }
      // DB更新する(castProps)
      const params = {
        cast_id: state.commentCastId,
        key: "working__comment",
        value: state.comment,
      };
      await saveProps(params);
      // 表を更新
      const index = propsCastlist.value.findIndex(
        (list) => list.cast_id == state.commentCastId
      );
      if (index != -1) {
        set(propsCastlist.value[index], "working_comment", state.comment);
      }
      cancelCommentDialog();
    };
    // コメントダイアログ閉じる
    const cancelCommentDialog = () => {
      state.commentDialog = false;
      state.commentCastId = 0;
      state.comment = "";
    };

    return {
      setting,
      propsCastlist,
      refWorkingForm,
      ...toRefs(state),
      itemRowBackground,
      onChangeListIsEnable,
      onChangePriority,
      modeColor,
      onChangeMode,
      onSubmitModeDialog,
      cancelModeDialog,
      onChangeComment,
      onSubmitCommentDialog,
      cancelCommentDialog,
      service_end_time_rules: [
        (v) =>
          (!!v && state.mode == setting.working_mode_service) ||
          "必須選択項目です",
      ],
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .mini label {
  font-size: 0.75em;
}
::v-deep .td_comment {
  min-width: 200px;
}
::v-deep .td_name {
  width: 180px;
}
::v-deep .td_profile {
  min-width: 120px;
}
.no_top_margin .v-input--selection-controls,
.no_top_margin .v-text-field {
  margin-top: 0;
  padding-top: 0;
}
.schedule_span {
  font-size: 0.75em;
}
::v-deep .td_shop_site {
  min-width: 200px;
}
.shop_site_span {
  font-size: 0.75em;
  background-color: #fafafa;
  word-break: break-all;
}
.truncate {
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate_comment {
  display: inline-block;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.work_status_label {
  font-size: 0.75em;
  font-weight: bold;
  padding: 2px;
  border-radius: 2px;
}
::v-deep .is_auto_cursor {
  cursor: pointer !important;
}
</style>
